<template>
  <div>
    <div v-if="loading">
      <div class="d-flex justify-content-between mx-5 mt-3 mb-5">
        <div class="spinner-border text-success align-self-center loader-lg"/>
      </div>
    </div>
    <div v-else-if="loadingError" class="cm-loading-error">
      {{ $t("calendarModule.errors.loadingError") }}
    </div>
    <div v-else>
      <b-form-group class="has-float-label">
        <template v-slot:label>
          {{ $t("calendarModule.messages.selectCalendar") }}
        </template>
        <v-select
          :options="selectOptions"
          v-model="selectedCalendar"
          @input="calendarSelected"
        />
      </b-form-group>
    </div>
  </div>
</template>

<script>
import * as cmApi from "../../../store/modules/calendarModule/calendarModule.api";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "CalendarDropdown",
  props: ["theCalendars", "theCalendar"],
  components: {
    vSelect
  },
  data() {
    return {
      cmApi: cmApi,
      loading: false,
      loadingError: false,
      calendars: [],
      selectedCalendar: null
    };
  },
  computed: {
    selectOptions() {
      return this.calendars.map(calendar => {
        return {
          label: calendar.name,
          code: calendar._id
        };
      });
    }
  },
  methods: {
    calendarSelected() {
      const id = this.selectedCalendar ? this.selectedCalendar.code : null;
      const calendar = this.calendars.find(calendar => calendar._id === id);
      this.$emit("changedSelection", calendar || null);
    }
  },
  created() {
   if (this.theCalendar && this.theCalendar._id) {
      this.selectedCalendar = {
        label: this.theCalendar.name,
        code: this.theCalendar._id
      };
    }

    if (!this.theCalendars) {
      this.calendars = [];
    } else {
      this.calendars = this.theCalendars;
    }
  }
};
</script>

<style scoped></style>
